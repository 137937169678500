<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1"> {{$t('creator.titleFilling')}} </h1>
        <p>{{$t('creator.text_add_Avatar')}}</p>
      </b-col>

      <!-- Avatar -->
      <b-col class="col-12 text-center mt-3">
        <h3 class="text-left">Avatar</h3>
        <b-card 
          class="card-container-avatar"
          body-class="border-card-avatar"
          header-class="header-color"
        >
          <template #header> 
            <b-avatar 
              variant="light"
              :src="avatar"
              size="10em" 
              class="avatar-profile">
            </b-avatar>
            <div>
            <b-avatar class="avatar-edit" variant="light"  href="#" @click="modalImage()">
              <b-icon icon="pencil-fill"/>
            </b-avatar>
            </div>
              
          </template>
          <div class="mt-4">
          </div>
        </b-card>
      </b-col>
      <!-- Avatar -->

      <!-- Overview -->
      <validation-observer ref="save" #default="{invalid}"> 
      <b-form>
      <b-col class="col-12 mt-3">
        <h3 class="text-left">{{$t('creator.profileOverview')}}</h3>
        <b-row>
          <b-col class="col-12">
            <b-row>
              <b-col class="col-6">
                <b-form-group>
                  <label for="account-username">{{$t('name')}}</label>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('name')"
                    :vid="$t('name')"
                    rules="required">
                  <b-form-input
                    v-model="first_name"
                    :placeholder="$t('name')"
                    :state="first_name.length > 0 && !existsEmailOrPhoneOrUlr(first_name)"
                    @keyup.enter="!invalid ? save(): ''"
                  />
                  <small class="text-danger d-block">{{ errors[0] }}</small>
                  <small class="text-danger w-100" v-if="existsEmailOrPhoneOrUlr(first_name) && loaded">{{ $t('negativeContent.findInfoContact') }}</small>
                 </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group>
                  <label for="account-lastname">{{$t('lastname')}}</label>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('lastname')"
                      :vid="$t('lastname')"
                      rules="required">
                    <b-form-input
                      v-model="last_name"
                      :placeholder="$t('lastname')"
                      :state="last_name.length > 0 && !existsEmailOrPhoneOrUlr(last_name)"
                      @keyup.enter="!invalid ? save(): ''"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger w-100" v-if="existsEmailOrPhoneOrUlr(last_name) && loaded">{{ $t('negativeContent.findInfoContact') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="col-12">
            <b-form-group >
              <label>{{ $t('dateBirth')  }}</label>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('birthday')"
                  :vid="$t('birthday')"
                  rules="required">
                  <input-calendar 
                    :real_date="birthday"
                    ref="input_calendar"
                    v-if="loaded"
                    :state="errors.length > 0 ? false : null"
                  ></input-calendar>
                <small class="text-danger" v-if="$refs['input_calendar'] && Object.keys($refs['input_calendar']).length > 0 && $refs['input_calendar'].date.length === 0">{{ $t('birthdayRequired') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group>
              <label>{{$t('creator.descriptionBio')}}</label>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('creator.questionText')"
                  :vid="$t('creator.questionText')"
                  tag="div"
                  rules="required"
                >
                <b-form-textarea
                  v-model="question_text"
                  :state="question_text.length > 0 && !existsEmailOrPhoneOrUlr(question_text)"
                  :class="{'is-invalid': !!errors.length}"
                />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger w-100" v-if="existsEmailOrPhoneOrUlr(question_text) && loaded">{{ $t('negativeContent.findInfoContact') }}</small>
             </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- Overview -->

      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="invalid || existsEmailOrPhoneOrUlr(first_name + last_name + question_text)" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save">{{ getTextButton() }}</b-button>
      </b-col>
      <b-col class="col-12 d-flex justify-content-center">
        <p>{{$t('creator.text_age_range')}}</p>
      </b-col>
      </b-form>
      </validation-observer>
    </b-row>
    <b-modal
      id="modal-center"
      centered
      :title="$t('dashboard.changeAvatar')"
      ref="change-avatar"
      :ok-title="$t('dashboard.save')"
      ok-only
      @ok="changeAvatar"
    >
      <b-form-file
        id="file-large"
        size="lg"
        v-model="file_avatar"
        :placeholder="$t('dashboard.chooseFile')"
        :drop-placeholder="$t('dashboard.dropFile')"
        accept=".jpg, .png"
        :browse-text="$t('uploadFile')"
      ></b-form-file>
      
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BIcon,
  BCard,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
} from 'bootstrap-vue';
import others from '@/services/others'
import profile_services from '@/services/settings';
import abilities from '@/libs/acl/abilities';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
import { existsEmailOrPhoneOrUlr } from '@/libs/utils/formats'
import { verifyAsset } from '@/libs/utils/urls'
import { getMotivationalPhrase } from '@/libs/utils/others'
import { setPermissions, setuserData } from '@/libs/utils/user'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'PublicProfileSetting',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BIcon,
    BCard,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    inputCalendar: () => import('@/views/components/inputs/inputCalendar.vue')
  },
  data() {
    return {
      verifyAsset,
      setuserData,
      setPermissions,
      getMotivationalPhrase,
      existsEmailOrPhoneOrUlr,
      required,
      length,
      avatar: '',
      file_avatar: null,
      first_name: '',
      last_name: '',
      birthday: '',
      question_text: '',
      has_birthday: false,
      interval_loading: null,
      editing: this.$route.params.is_edit ? this.$route.params.is_edit : '',
      loaded: false,
    }
  },
  created() {
    localize(this.$i18n.locale)
    this.getProfile()
  },
  methods: {
    changeAvatar() {  
      if (this.file_avatar) {
        profile_services.changeAvatar(this.file_avatar).then(result => {
          this.file_avatar = null;
          this.avatar = result.user.profile_image ? verifyAsset(result.user.profile_image) : ''
        }).catch(() => {
        });
      }
    },
    getProfile () {
      others.getProfile().then(response => {
        const user = response.user
        if (user.meta_info && user.meta_info.birthday) this.has_birthday = true
        this.avatar = user.profile_image ? verifyAsset(user.profile_image) : ''
        this.first_name = user.first_name
        this.last_name = user.last_name
        this.birthday = user.meta_info && user.meta_info.birthday ? user.meta_info.birthday : ''
        this.question_text = user.question_text
        this.loaded = true
      })
    },
    getTextButton() {
      if (this.editing) return this.$t('dashboard.save')
      else return this.$t('creator.butonNext')
    },
    modalImage() {
      this.file_avatar = null;
      this.$refs["change-avatar"].toggle("#toggle-btn");
    },
    save() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      this.birthday = this.$refs['input_calendar'].getDate();
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        birthday: this.birthday,
        question_text: this.question_text
      }
      others.updateProfile(data).then(response => {
        if (response.status >= 400) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              position: 'top-right',
              props: {
                title: this.$t('errorTitle'),
                icon: 'XIcon',
                variant: 'danger',
                text: this.$t('errorText')
              },
            });
          })
        } else {   
          setPermissions(response.user);
          this.readAbility = abilities.getAbilitie(response.user) 
          
          setuserData(response, this.readAbility)
          if (this.editing) {
            this.$router.push({name: 'my_work', params: {section: 'networks'}});
            this.$root.$emit('quit_navbar_white');
          } else {
            this.$router.push({ name: 'setting-public-profile', params: {section: 'basic_info'}})
          }
        }

        clearInterval(this.interval_loading)
        this.$vs.loading.close()
      })
        .catch(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    },
  },
}
</script>
<style>
.card-container-avatar {
  border-radius: 0.75rem !important;
}
.border-card-avatar {
  border: 0.0625rem solid rgba(222,226,230,.7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.header-color {
  background: linear-gradient(to left bottom, #7cd6af, #3e7ba0);
  justify-content: center !important;
  height: 130px;
  border: 0.0625rem solid rgba(222,226,230,.7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}
.avatar-profile {
  position: absolute;
  border: 0.125rem dashed rgba(222,226,230,.7);
  bottom: -20px;
  font-weight: 100 !important;
  padding: 3px;
}
.avatar-edit {
  position: absolute;
  bottom: -17px;
  color: #495057;
  margin-left: 30px;
}
.avatar-edit:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
    color: black;
}
</style>